import { useForm } from "react-hook-form";
import styles from "./PatientAuthentication.module.css";
import { useState } from "react";
import {
  matchDayRegex,
  matchMonthRegex,
  matchYearRegex,
} from "../../constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "../../components/Button/Button";
import { authenticatePatient } from "./api";
import Loader from "../../components/Loader";

interface IFormInput {
  day: string;
  month: string;
  year: string;
  zipCode: string;
}

export default function PatientAuthentication() {
  const {
    register,
    handleSubmit,

    formState: { errors, isSubmitting },
  } = useForm<IFormInput>({ mode: "onSubmit" });

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [networkError, setNetworkError] = useState("");

  const onSubmit = (data: IFormInput) => {
    const date = `${data.year}-${data.month}-${data.day}`;
    const accessCode = searchParams.get("access_code") ?? "";

    return authenticatePatient(date, data.zipCode, accessCode)
      .then(() => navigate("/info"))
      .catch((e) => {
        if (e.response?.data?.error) {
          setNetworkError(e.response.data.error);
        } else {
          setNetworkError("Invalid credentials");
        }
      });
  };

  const dobFormatError =
    errors.day || errors.month || errors.year
      ? "Please enter a valid date"
      : "";

  return (
    <Loader>
      <form
        className={styles.identityConfirmation}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={styles.content}>
          <h1 className={styles.title}>Verify your identity</h1>
          <p className={styles.subtext}>
            Please enter your zip code and date of birth so that we can verify
            your identity.
          </p>
          <div className={styles.inputsBox}>
            <div>
              <p className={styles.label}>Zip code</p>
              <div className={`${styles.inputs} ${styles.nameInputs}`}>
                <input
                  {...register("zipCode", {
                    required: true,
                  })}
                  placeholder="Zip code"
                  className={errors.zipCode ? styles.hasError : undefined}
                  data-testId={"PatientAuth__zip_code"}
                ></input>
              </div>
              {errors.zipCode && (
                <p className={styles.error}>{errors.zipCode.message}</p>
              )}
            </div>
            <div>
              <p className={styles.label}>Date of birth</p>
              <div className={styles.inputs}>
                <input
                  {...register("month", {
                    pattern: matchMonthRegex,
                    required: true,
                  })}
                  className={`${styles.monthInput} ${
                    errors.month ? styles.hasError : ""
                  }`}
                  placeholder="MM"
                  type="number"
                  maxLength={2}
                  data-testId={"PatientAuth__dob_month"}
                ></input>
                <input
                  {...register("day", {
                    pattern: matchDayRegex,
                    required: true,
                  })}
                  className={`${styles.dayInput} ${
                    errors.day ? styles.hasError : ""
                  }`}
                  placeholder="DD"
                  maxLength={2}
                  type="number"
                  data-testId={"PatientAuth__dob_day"}
                ></input>
                <input
                  {...register("year", {
                    pattern: matchYearRegex,
                    required: true,
                  })}
                  className={`${styles.yearInput} ${
                    errors.year ? styles.hasError : ""
                  }`}
                  placeholder="YYYY"
                  type="number"
                  maxLength={4}
                  data-testId={"PatientAuth__dob_year"}
                ></input>
              </div>
              {dobFormatError && (
                <p className={styles.error}>{dobFormatError}</p>
              )}
              {networkError && <p className={styles.error}>{networkError}</p>}
            </div>
          </div>
          <Button
            width="100%"
            submit={true}
            loading={isSubmitting}
            testId="PatientAuth__continue"
          >
            Continue
          </Button>
        </div>
      </form>
    </Loader>
  );
}
