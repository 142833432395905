import instance from "../../api/http_client";

export function authenticatePatient(
  dob: string,
  zipCode: string,
  accessCode: string
) {
  return instance.post(`consent/patient/authenticate`, {
    dob: dob,
    zip_code: zipCode,
    access_code: accessCode,
  });
}
